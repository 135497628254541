var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$store.getters["user/getPermissions"]("view_trial_tariff")
    ? _c(
        "div",
        { staticClass: "ctrial-group" },
        [
          _c(
            "CButton",
            {
              staticClass: "shadow-none tryBtn",
              attrs: { color: _vm.btnColor },
              on: { click: _vm.clickTrialBtn },
            },
            [_vm._v(" " + _vm._s(_vm.btnText) + " ")]
          ),
          _c(
            "CModal",
            {
              attrs: {
                title: _vm.$t("lk.subscribe.trial.modalTitle"),
                centered: "",
                show: _vm.showTrialWithoutTg,
              },
              on: {
                "update:show": function ($event) {
                  _vm.showTrialWithoutTg = $event
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "w-100" },
                          [
                            _c(
                              "CLoadingButtonCustom",
                              {
                                staticClass: "mr-2",
                                attrs: { color: "primary" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.clickTelegramVerify.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("lk.subscribe.trial.confirm")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "CButton",
                              {
                                attrs: { color: "dark" },
                                on: {
                                  click: function ($event) {
                                    _vm.showTrialWithoutTg = false
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("lk.subscribe.trial.cancel")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                4112878352
              ),
            },
            [
              _c("p", [
                _vm._v(_vm._s(_vm.$t("lk.subscribe.trial.modalSubtitle"))),
              ]),
            ]
          ),
          _c(
            "CModal",
            {
              attrs: {
                title: _vm.$t("lk.subscribe.trial.modalTitle"),
                centered: "",
                show: _vm.showTrialWithTg,
              },
              on: {
                "update:show": function ($event) {
                  _vm.showTrialWithTg = $event
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "w-100" },
                          [
                            _c(
                              "CLoadingButtonCustom",
                              {
                                staticClass: "mr-2",
                                attrs: {
                                  color: "primary",
                                  loading: _vm.activateTrialLoading,
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.activateTrial.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("lk.subscribe.trial.activate")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "CButton",
                              {
                                attrs: { color: "dark" },
                                on: {
                                  click: function ($event) {
                                    _vm.showTrialWithTg = false
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("lk.subscribe.trial.cancel")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2141085627
              ),
            },
            [
              _vm.userTariffIsProAlerts
                ? _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("lk.subscribe.trial.modalSubtitleProAlerts")
                      )
                    ),
                  ])
                : _vm._e(),
              !_vm.userTariffIsProAlerts
                ? _c("p", [
                    _vm._v(
                      _vm._s(_vm.$t("lk.subscribe.trial.modalSubtitleTg1"))
                    ),
                  ])
                : _vm._e(),
              !_vm.userTariffIsProAlerts
                ? _c("p", [
                    _vm._v(
                      _vm._s(_vm.$t("lk.subscribe.trial.modalSubtitleTg2"))
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }