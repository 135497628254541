<template>
  <div class="ctrial-group" v-if="$store.getters['user/getPermissions']('view_trial_tariff')">
    <CButton
      :color="btnColor"
      class="shadow-none tryBtn"
      @click="clickTrialBtn"
    >
      {{ btnText }}
    </CButton>

    <CModal
      :title="$t('lk.subscribe.trial.modalTitle')"
      centered
      :show="showTrialWithoutTg"
      @update:show="showTrialWithoutTg = $event"
    >
      <p>{{$t('lk.subscribe.trial.modalSubtitle')}}</p>

      <template v-slot:footer>
        <div class="w-100">
          <CLoadingButtonCustom
            color="primary"
            class="mr-2"
            @click.native="clickTelegramVerify"
          >
            {{$t('lk.subscribe.trial.confirm')}}
          </CLoadingButtonCustom>
          <CButton
            color="dark"
            @click="showTrialWithoutTg = false"
          >
            {{$t('lk.subscribe.trial.cancel')}}
          </CButton>
        </div>
      </template>
    </CModal>

    <CModal
      :title="$t('lk.subscribe.trial.modalTitle')"
      centered
      :show="showTrialWithTg"
      @update:show="showTrialWithTg = $event"
    >
      <p v-if="userTariffIsProAlerts">{{$t('lk.subscribe.trial.modalSubtitleProAlerts')}}</p>
      <p v-if="!userTariffIsProAlerts">{{$t('lk.subscribe.trial.modalSubtitleTg1')}}</p>
      <p v-if="!userTariffIsProAlerts">{{$t('lk.subscribe.trial.modalSubtitleTg2')}}</p>
      <template v-slot:footer>
        <div class="w-100">
          <CLoadingButtonCustom
            color="primary"
            class="mr-2"
            :loading="activateTrialLoading"
            @click.native="activateTrial"
          >
            {{$t('lk.subscribe.trial.activate')}}
          </CLoadingButtonCustom>
          <CButton
            color="dark"
            @click="showTrialWithTg = false"
          >
            {{$t('lk.subscribe.trial.cancel')}}
          </CButton>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "TrialGroup",
  props: {
    btnColor: {
      type: String,
      default: 'primary'
    },
    btnText: {
      type: String,
      default: function () {
        return this.$t('lk.subscribe.trial.trialPeriod')
      }
    }
  },
  data () {
    return {
      showTrialWithoutTg: false,
      showTrialWithTg: false,
      activateTrialLoading: false
    }
  },
  computed: {
    userTariffActive () {
      return this.$store.state.user.userData.tariffIsActive
    },
    userTariffId () {
      if (!this.userTariffActive) return ''
      return this.$store.state.user.userData.tariff.id
    },
    userTariffIsProAlerts () {
      return this.userTariffActive && this.userTariffId === 'pro_alerts'
    },
    getToDate () {
      const date = this.$store.state.user.userData.tariffExpired
      if (!date) return ''

      const dateFormat = new moment(date)
      if (!dateFormat.isValid()) return ''

      return `${this.$t('general.until')} ${dateFormat.format('DD.MM.YY')}`
    }
  },
  methods: {
    clickTrialBtn () {
      if (this.$store.getters['user/telegramVerify']) {
        this.showTrialWithTg = true
      } else {
        this.showTrialWithoutTg = true
      }
    },
    clickTelegramVerify() {
      window.open(this.$store.state.user.userData.tgLink)

      this.checkTelegramVerify()
    },
    checkTelegramVerify() {
      const oldTimer = this.$store.state.user.telegramVerifyTimer
      if (oldTimer) {
        clearTimeout(oldTimer)
        this.$store.commit('user/SET_TELEGRAM_VERIFY_TIMER', null)
      }

      const timer = setTimeout(async () => {
        await this.$store.dispatch('user/getCurrent')
        if (this.$store.getters['user/telegramVerify']) {
          this.$toastr.success(this.$t('lk.subscribe.trial.confirmSuccess'))
          this.showTrialWithoutTg = false
          this.showTrialWithTg = true
        } else {
          this.checkTelegramVerify()
        }
      }, 7000)
      this.$store.commit('user/SET_TELEGRAM_VERIFY_TIMER', timer)
    },
    activateTrial () {
      this.activateTrialLoading = true
      axios.post('api/v2/tariffs/trial')
        .then(({data, status}) => {
          if (status < 300 && data.tariff_changed) {
            if(data.enable_trial){
              this.$toastr.success(data.message || this.$t('lk.subscribe.trial.activateSuccess'))
            }
            this.$store.dispatch('user/getCurrent').then(({status}) => {
              if (status < 300) {
                this.$root.$emit('dropdownMenuUpdate')
              }
            })
          }
        })
        .finally(() => {
          this.activateTrialLoading = false
          this.showTrialWithTg = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.ctrial-group {
  display: flex;
  @include media-breakpoint-up(xl) {
    justify-content: flex-end;
  }
}
</style>
